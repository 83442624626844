@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";




#post {
  @apply text-base leading-loose;


  h1 {
      @apply text-2xl font-bold mb-10 pt-4 pb-4;
  }

  h2 {
      @apply text-xl font-bold mb-10 p-4 bg-gray-100;
  }

  h3 {
      @apply text-lg font-bold mb-10 pl-4;
      border-left: solid 3px #333;
  }

  h4 {
      @apply text-base font-bold mb-10 border-t border-b pt-4 pb-4;
  }

  h5 {
      @apply text-sm font-bold mb-10 border-b pt-2 pb-2;
  }

  h6 {
      @apply text-xs font-bold mb-10 border-b pt-1 pb-1;
  }

  p {
      @apply text-base mb-10;
  }

  blockquote {
      @apply italic text-gray-500 p-6 bg-white rounded-lg shadow-xl mb-10;
      p{
        margin-bottom: 0;
      }
  }

  table {
      @apply table-auto;
  }

  b {
      @apply font-bold;
  }

  i {
      @apply italic;
  }

  ul {
    @apply mb-10 ml-6;
      li {
          @apply list-disc;
      }
  }

  ol {
    @apply mb-10 ml-6;
      li {
          @apply list-decimal;
      }
  }
  strong{
    @apply font-bold;
  }
  em{
    @apply font-bold italic;
  }
}